import { createContext, useContext, useState } from 'react';

const TabSwitchContext = createContext();

export const TabSwitchProvider = ({ children }) => {
  const [count, setCount] = useState(0);

  const incrementCount = () => {
    setCount((prevCount) => prevCount + 1);
    // console.log(count);
  };

  return (
    <TabSwitchContext.Provider value={{ count, incrementCount }}>
      {children}
    </TabSwitchContext.Provider>
  );
};

export const useTabSwitch = () => {
  return useContext(TabSwitchContext);
};
