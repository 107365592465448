import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import classes from "./resumeModal.module.css";

// Set the worker source for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/legacy/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const ResumeModal = ({ isOpen, closeModal, candidateRef }) => {
  const [resumeUrl, setResumeUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isOpen && candidateRef.current) {
      setLoading(true); // Show the loader
      const url = candidateRef?.current?.candidate?.Resume || "";
      setResumeUrl(url);
    }
  }, [isOpen, candidateRef]);

  const handleCloseModal = () => {
    setResumeUrl(""); // Clear the URL to avoid caching issues
    closeModal(); // Close the modal
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages); // Store the number of pages
    setLoading(false); // Hide the loader
  };

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 ${
          isOpen ? "opacity-100 visible" : "opacity-0 invisible"
        } transition-opacity duration-300 ease-in-out`}
      >
        <div
          className="fixed inset-0 bg-black bg-opacity-50"
          onClick={handleCloseModal}
        ></div>
        <div className="relative bg-white rounded-lg shadow-lg w-fit max-w-3xl mx-4 my-8 p-4 sm:p-6 lg:p-4 flex flex-col gap-2 overflow-hidden">
          <button
            onClick={handleCloseModal}
            className="w-full flex justify-end text-gray-500 hover:text-gray-700 transition-colors"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-x"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
          <div className={`overflow-auto max-h-[80vh] ${classes.resumeModal}`}>
            {loading && (
              <div className="flex justify-center items-center h-full">
                <svg
                  className="animate-spin h-8 w-8 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
              </div>
            )}
            <Document
              file={resumeUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) =>
                console.error("Error loading PDF: ", error)
              }
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  className="mr-1"
                />
              ))}
            </Document>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResumeModal;
