import React, { useEffect, useState } from "react";
import Card from "../../components/portal/Card";
import { Footer } from "../../components/Footer";
import Filter from "../../components/portal/Filter";
import { getAllJobPostings } from "../../service/api";
import "../../components/customCss/listingPage.css";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlass } from "react-loader-spinner";
import CandidateNavbar from "../../components/NavBar/CandidateNavbar";
import Pagination from "../../components/dashboard/Pagination";

function Listing() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [initialCounter, setInitialCounter] = useState(0);
  const [counter, setCounter] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const perPage = 6;
  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
    getAllJobPostings(page)
      .then((jobs) => {
        setJobs(jobs.jobList);
        setTotalPages(jobs.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      const jobs = await getAllJobPostings(currentPage);
      setJobs(jobs.jobList);
      setTotalPages(jobs.totalPages);
      setLoading(false);
    };
    fetchJobs();
  }, [currentPage]);

  const first5Data = jobs.slice(initialCounter, counter);

  // console.log(first5Data);

  return (
    <div className="bg-gray-100">
      {loading ? (
        <div className="magnifying-glass-wrapper flex flex-col items-center justify-center h-screen">
          <MagnifyingGlass
            visible={true}
            height="160"
            width="160"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#2979FF"
          />
        </div>
      ) : (
        <div>
          <div className="bg-gray-100 md:contain">
            <CandidateNavbar />
            {/* Listing Page of Jobs with Filter */}
            <div className="lg:grid lg:grid-cols-4 md:grid  md:grid-cols-4  bg-gray-100">
              {/* side bar */}

              <div className="sidebar lg:grid lg:grid-cols-1 md:grid md:grid-cols-1 h-screen bg-gray-100">
                <Filter />
              </div>
              {loading && <h1>Loading...</h1>}
              {!loading && jobs.length === 0 && (
                <h1 className="m-5">No Jobs Found</h1>
              )}

              {/* Job Description Cards */}
              <div className="h-fit mb-2 inner-container lg:col-span-3 md:col-span-3 bg-gray-100 lg:mt-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-4 gap-x-4 lg:mr-4 p-4 md:py-4 md:pr-4 lg:px-2 lg:py-0">
                  {first5Data.map((job, index) => (
                    <Card
                      className="box"
                      key={index}
                      cardData={job}
                      navigate={navigate}
                      id={job._id}
                    />
                  ))}
                </div>
                <div className="flex items-end justify-end">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    perPage={perPage}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="footer bg-gray-100 lg:row-start-2 lg:col-span-4 sm: md:row-start-2 md:col-span-4">
            <Footer showBookingSlot={false} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Listing;
