import React, { useState, useEffect } from "react";
import "../customCss/applicant.css";
import { getAllJobApplications, getCandidateStats } from "../../service/api";
import { format } from "date-fns";
import { MagnifyingGlass } from "react-loader-spinner";

const CanInterview = () => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [stats, setStats] = useState([]);
  useEffect(() => {
    const token = getCookieAndRole();
    getAllJobApplications(token.token)
      .then((data) => setAppliedJobs(data))
      .catch((error) => console.error("Error fetching data:", error));
    const fetchStats = async () => {
      try {
        const stats = await getCandidateStats(token.token);
        // ////console.log(stats);
        setStats(stats);
      } catch (error) {
        console.error("Error fetching stats", error);
      }
    };

    fetchStats();
  }, []);
  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const handleCancelApplication = () => {
    // implement cancel application here
  };
  const handleAcceptInterview = () => {
    // implement accept interview here
  };
  // const handleRescheduleInterview = () => {
  //   // implement reschedule interview here
  // };
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [rescheduleReasons, setRescheduleReasons] = useState({});

  const handleRescheduleInterview = (jobId) => {
    setShowRescheduleModal(jobId);
  };

  const handleCancelReschedule = () => {
    setShowRescheduleModal(null);
  };

  const handleConfirmReschedule = (jobId) => {
    const reason = rescheduleReasons[jobId];
    // console.log("Reschedule reason for job", jobId, ":", reason);
    setRescheduleReasons((prev) => ({
      ...prev,
      [jobId]: "",
    }));
    setShowRescheduleModal(null);
  };

  return (
    <div className="flex-col p-2 contain rounded-lg bg-white">
      <div className="text-4xl heading text-gray-700 mb-8 font-semibold">
        Applicant's Interview <span className="text-blue-600">Overview</span>
      </div>

      {/*Stats of the applicant's in one line with boxes*/}
      {/* {stats.data ? (
        <div className="responsive-box flex flex-wrap mb-7 sm:space-x-4">
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl pl-3 pt-3">
              Total no of Applications
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.totalJobApplications}
            </div>
          </div>
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl p-3">
              No of Active Applications
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.totalJobListingsWithActiveStatus}
            </div>
          </div>
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl pl-3 pt-3">
              Shortlisted Applications
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.totalShortlistedJobListings}
            </div>
          </div>
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl pl-3 pt-3">
              In-touch
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.noOfInterviews}
            </div>
          </div>
        </div>
      ) : (
        <div className="magnifying-glass-wrapper flex items-center justify-center h-full">
          <MagnifyingGlass
            visible={true}
            height="160"
            width="160"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#2979FF"
          />
        </div>
      )} */}

      <hr className="border-t  border-gray-300"></hr>
      <div className="mx-auto tables">
        <div className="max-h-[350px] overflow-y-auto">
          <table className="min-w-full bg-white table-auto">
            <thead>
              <tr>
                <th className="border border-gray-300 p-2">SNo.</th>
                <th className="border border-gray-300 p-2">Company</th>
                <th className="border border-gray-300 p-2">Role</th>
                <th className="border border-gray-300 p-2">Date Applied</th>
                <th className="border border-gray-300 p-2">Salary</th>
                <th className="border border-gray-300 p-2">Status</th>
                <th className="border border-gray-300 p-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {appliedJobs.map((job, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 p-2 text-center">
                    {index + 1}
                  </td>
                  <td className="py-3 px-4  border border-gray-300 text-center">
                    {job.job_listing.companyName &&
                    job.job_listing.companyName.brandName
                      ? job.job_listing.companyName.brandName
                      : "null"}
                  </td>
                  <td className="py-3 px-4 border border-gray-300 text-center">
                    {job.job_listing.jobTitle || "null"}
                  </td>
                  <td className="py-3 px-4 border border-gray-300 text-center">
                    {job.updatedAt
                      ? format(new Date(job.updatedAt), "dd-MMM-yyyy")
                      : "null"}
                  </td>
                  <td className="py-3 px-4 border border-gray-300 text-center">
                    {job.job_listing.salary || "null"}
                  </td>
                  <td
                    className={`text-center py-3 px-4 border border-gray-300`
                  }
                  >
                    {/* implement status here */}
                    {job.status === "pending" && <p className="bg-yellow-600 rounded-3xl py-2">pending</p>}
                    {job.status === "shortlisted" && <p className="bg-green-400 rounded-3xl py-2">shortlisted</p>}
                  </td>
                  <td className=" border border-gray-300 text-center">
                    {/* implement actions here */}
                    {job.status === "pending" && (
                      <button
                        onClick={handleCancelApplication}
                        className="bg-red-400 px-2 py-1 rounded-md text-white hover:bg-red-600"
                      >
                        cancel
                      </button>
                    )}
                    {job.status === "shortlisted" && (
                      <div className="flex flex-nowrap justify-evenly items-center">
                        <button className="bg-green-400 px-2 py-1 rounded-md text-white hover:bg-green-600">
                          accept
                        </button>
                        <button
                          className="bg-blue-500 px-2 py-1 rounded-md text-white hover:bg-blue-600"
                          onClick={() => handleRescheduleInterview(job._id)}
                        >
                          reschedule
                        </button>
                      </div>
                    )}
                   
                    {showRescheduleModal && (
                      <div className="fixed top-0 left-0 flex justify-center items-center w-full h-full bg-gray-800 bg-opacity-75 z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                          <h2 className="text-lg font-semibold mb-4">
                            Reschedule Interview
                          </h2>
                          <textarea
                            value={rescheduleReasons[showRescheduleModal] || ""}
                            onChange={(e) =>
                              setRescheduleReasons((prev) => ({
                                ...prev,
                                [showRescheduleModal]: e.target.value,
                              }))
                            }
                            placeholder="Enter reason for rescheduling..."
                            className="border outline-none border-gray-300 rounded-lg p-2 mb-4 h-[15rem] w-[25rem] resize-none focus:border-blue-300"
                            rows={4}
                          ></textarea>
                          <div className="flex justify-end">
                            <button
                              onClick={handleCancelReschedule}
                              className="bg-gray-400 text-white px-4 py-2 rounded-lg mr-4"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() =>
                                handleConfirmReschedule(showRescheduleModal)
                              }
                              className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="md:hidden mt-6">&nbsp;</div>
    </div>
  );
};

export default CanInterview;
