import React from 'react'
import { Navbar } from '../../components/Navbar'
import  Header from '../../components/portal/Header';
import { Steps } from '../../components/portal/Steps';
import { Categories } from '../../components/portal/Categories';
import { Faq } from '../../components/portal/Faq';
import { Footer } from '../../components/Footer';
import Sliding from '../../components/Sliding';
import CandidateNavbar from '../../components/NavBar/CandidateNavbar';


function Landing() {
  return (
    <div>
    <CandidateNavbar />
    <Header />
    <Steps/>
    <Categories/>
    <Faq />
    <Sliding/>
    <Footer showBookingSlot={false} />
    </div>
  )
}

export default Landing
