import React, { useState, useEffect, useRef } from "react";
import Modal from "../../components/dashboard/Modal";
import { getAllJobsPosted, shortlistedCandidates } from "../../service/api";
import "../customCss/applicant.css";
import ScheduleInterviewModal from "./ScheduleInterviewModal";

const Interview = () => {
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState("");
  const [candidatesData, setCandidatesData] = useState([]);
  const selectedCandidateRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateStatus, setCandidateStatus] = useState({});
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);

  useEffect(() => {
    const token = getCookieAndRole();
    const getAllJobsPost = async (token) => {
      const getjobs = await getAllJobsPosted(token.token);
      setJobs(getjobs);
    };
    getAllJobsPost(token);
  }, []);

  useEffect(() => {
    setCandidatesData([]);

    const fetchCandidatesData = async () => {
      if (jobId) {
        const candidates = await shortlistedCandidates(jobId);

        if (candidates.status === 200) {
         setCandidatesData(candidates.data.candidatesDetails); 
          // console.log(candidates.data.candidatesDetails);
        }else{
          setCandidatesData([]);
        }
      }
    };
    fetchCandidatesData();
  }, [jobId]);

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const openModal = (candidate) => {
    selectedCandidateRef.current = candidate;
    setIsModalOpen(true);
  };

  const closeModal = () => {
    selectedCandidateRef.current = null; // Reset the selected candidate reference
    setIsModalOpen(false);
  };

  const handleSelectChange = (event) => {
    setJobId(event.target.value);
  };

  const handleScheduled = () => {
    const updatedStatus = { ...candidateStatus };
    updatedStatus[selectedCandidateRef.current.candidate._id] = "scheduled";
    setCandidateStatus(updatedStatus);
    setIsScheduleModalOpen(false); // Close the schedule modal after scheduling
  };

  const handleOpenSchedule = (candidate) => {
    selectedCandidateRef.current = candidate;
    setIsScheduleModalOpen(true);
  };

  const handleCloseSchedule = () => {
    setIsScheduleModalOpen(false);
  };

  return (
    <div className="bg-white border rounded-lg shadow-lg mb-3 p-5 mt-4">
      {/* Top Section with Dropdown */}
      <div className="flex justify-between candidate-heading items-center">
        <div className="relative">
          <label
            className="text-2xl text-gray-700 font-semibold"
            htmlFor="jobRoles"
          >
            Job Roles
          </label>
          <select
            id="jobRoles"
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-3 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            onChange={handleSelectChange}
            value={jobId} // Controlled component to set the selected value
          >
            <option className="text-blue-900" value="">
              Select a job
            </option>
            {jobs.map((job) => (
              <option key={job._id} value={job._id}>
                {job.jobTitle}
              </option>
            ))}
          </select>
        </div>
        <div className="flex space-x-4 text-gray-600">
          {/* Abbreviations */}
          <div>
            Select <span className="font-boldx"> "Interview"</span> to inform
            student for interview round. <br />
            You can connect with candidate over E-Mail or Call for further
            discussion.
          </div>
        </div>
      </div>

      {/* Candidate Table */}
      <div className="overflow-x-auto mt-5">
        <table className="min-w-full bg-white table-auto">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">SNo.</th>
              <th className="border border-gray-300 p-2">Name</th>
              <th className="border border-gray-300 p-2">Email</th>
              <th className="border border-gray-300 p-2">Mobile No.</th>
              <th className="border border-gray-300 p-2">Score</th>
              <th className="border border-gray-300 p-2">PoW</th>
              <th className="border border-gray-300 p-2">Status</th>
              <th className="border border-gray-300 p-2">Action</th>
            </tr>
          </thead>
          {candidatesData.length > 0 ? (
            <tbody>
              {candidatesData.map((candidate, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 p-2 text-center">
                    {index + 1}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {candidate.name}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {candidate.email}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {candidate.mobno}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {(
                      (candidate.role_related?.score?.ques1?.score +
                        candidate.role_related?.score?.ques2?.score) /
                        2 +
                      candidate.scoreExcel +
                      candidate.scoreAnalytical +
                      candidate.scoreReasoning +
                      (candidate.creative_related?.score?.ques1
                        ?.score +
                        candidate.creative_related?.score?.ques2
                          ?.score) /
                        2 +
                      candidate.resume_score
                    ).toFixed(2)}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    <button
                      onClick={() => openModal(candidate)}
                      className="text-blue-500 hover:underline cursor-pointer"
                    >
                      View PoW
                    </button>
                    <Modal
                      isOpen={isModalOpen}
                      closeModal={closeModal}
                      candidateRef={selectedCandidateRef}
                    />
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {candidateStatus[candidate._id] === "pending" && (
                      <p className="bg-yellow-600 rounded-3xl py-2">pending</p>
                    )}
                    {candidateStatus[candidate._id] ===
                      "scheduled" && <p className="bg-green-400 rounded-3xl py-2">scheduled</p>}
                  </td>
                  <td className="border border-gray-300 p-2 text-center">
                    {candidateStatus[candidate._id] === "pending" && (
                      <button
                        onClick={() => handleOpenSchedule(candidate)}
                        className="bg-blue-500 text-white px-2 py-1 rounded-md hover:bg-blue-700"
                      >
                        schedule
                      </button>
                    )}
                    {candidateStatus[candidate._id] ===
                      "scheduled" && (
                      <button
                        onClick={() => handleOpenSchedule(candidate)}
                        className="bg-blue-500 text-white px-2 py-1 rounded-md hover:bg-blue-700"
                      >
                        reschedule
                      </button>
                    )}
                    {isScheduleModalOpen &&
                      selectedCandidateRef.current === candidate && (
                        <ScheduleInterviewModal
                          // candidate={candidate}
                          closeModal={handleCloseSchedule}
                          handleScheduled={handleScheduled}
                        />
                      )}
                  </td>                 
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  colSpan="8"
                  className="border border-gray-300 p-2 text-center"
                >
                  <p className="text-gray-500">
                    No students applied till now. Please wait for 24 hours.
                  </p>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default Interview;
