import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const API_URI = "https://hiremeclub-backend.azurewebsites.net/api/v1";
//to get all the job postings
export const getAllJobPostings = async (page) => {
  try {
    const response = await axios.get(`${API_URI}/jobPostings?page=${page}`, {
      headers: {
        headers,
      },
    });
    // console.log("data aa rha ", response.data);
    return response.data;
  } catch (error) {
    console.log("error in getting the response (jobs) ", error);
  }
};
//get all the jobs to which a candidate has applied
export const getAllJobApplications = async (token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.get(`${API_URI}/appliedJobs/${user}/jobs`, {
      headers: {
        headers,
      },
    });
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

export const getEmployerDetails = async (token) => {
  try {
    const id = await getEmployerID(token);
    const response = await axios.get(`${API_URI}/Employer/${id}`, {
      headers: {
        headers,
      },
    });
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//get the userID from the JWT token->Candidates
export const getUserID = async (token) => {
  try {
    const response = await axios.post(
      `${API_URI}/UserID`,
      {
        token: token,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data.userId;
  } catch (error) {
    ////console.log(error);
    //console.log("Error in getting user ID from token");
  }
};

//to get the details of a particular candidate
export const getCandidateDetails = async (id) => {
  try {
    // //console.log("ID", id);
    const response = await axios.post(
      `${API_URI}/CandidateSide`,
      {
        id: id,
      },
      {
        headers: {
          headers,
        },
      }
    );

    return response.data;
  } catch (error) {
    //console.log("Error in getting candidate details", error);
  }
};

//to get all the jobs posted by a particular employer
export const getAllJobsPosted = async (token) => {
  try {
    const user = await getEmployerID(token);
    const response = await axios.get(
      `${API_URI}/jobsPosted/${user}/postedJobs`,
      {
        headers: {
          headers,
        },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

//to get all the candidates applied to a particular job
export const getAllCandidatesApplied = async (jobid) => {
  try {
    const response = await axios.post(
      `${API_URI}/job/candidates`,
      {
        jobId: jobid,
      },
      {
        headers: {
          headers,
        },
      }
    );
    // console.log(response);
    return response;
  } catch (error) {
    ////console.log(error);
  }
};

//to get the details of a particular job posting
export const getJobDetails = async (id) => {
  try {
    const response = await axios.get(`${API_URI}/job-details/${id}`, {
      headers: {
        headers,
      },
    });
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to add job postings to wishlist

export const addToWishlist = async (token, jobid) => {
  try {
    const user = await getUserID(token);
    const response = await axios.put(
      `${API_URI}/wishlist`,
      {
        userId: user,
        jobId: jobid,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//Applying to a Job posting
export const applyToJob = async (token, jobid, empid) => {
  try {
    ////console.log(jobid);
    ////console.log(empid);
    const user = await getUserID(token);
    ////console.log(user);
    const response = await axios.post(
      `${API_URI}/Candidate/applyJob`,
      {
        candidate: user,
        job_listing: jobid,
        employer: empid,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error.message);
  }
};

//get the candidate details of a candidate to display on profile
export const getCandidateProfile = async (token) => {
  try {
    const user = await getUserID(token);
    ////console.log(user);
    const response = await axios.post(
      `${API_URI}/candidateDetails`,
      {
        user: user,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//store the score of Excel, Analytical and Reasoning in database
export const storeMCQScore = async (
  token,
  scoreExcel,
  scoreReasoning,
  scoreAnalytical
) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/storeMCQScore`,
      {
        user: user,
        scoreExcel: scoreExcel,
        scoreReasoning: scoreReasoning,
        scoreAnalytical: scoreAnalytical,
      },
      {
        headers: {
          headers,
        },
      }
    );
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//get the userID from the JWT token->Employer
export const getEmployerID = async (token) => {
  try {
    const response = await axios.post(
      `${API_URI}/employerUserID`,
      {
        token: token,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data.userId;
  } catch (error) {
    ////console.log(error);
  }
};

//get the 5 Candidates from latest job posted by an employer
export const getCandidatesLatestJobPosting = async (token) => {
  try {
    const user = await getEmployerID(token);
    const response = await axios.post(
      `${API_URI}/EmpDash`,
      {
        userId: user,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to change the status of shortlisted candidates
export const toggleShortlistedStatus = async (jobid, id) => {
  try {
    const response = await axios.put(
      `${API_URI}/shortlistedCandidates`,
      {
        jobId: jobid,
        userId: id,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to get the shortlisted candidates for a JobId
export const shortlistedCandidates = async (jobid) => {
  try {
    const response = await axios.post(
      `${API_URI}/getShortlistedCandidates`,
      {
        jobId: jobid,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response;
  } catch (error) {
    ////console.log(error);
  }
};

//to change the Interview status of shortlisted candidates
export const toggleInterviewStatus = async (jobid, id, status) => {
  try {
    const response = await axios.post(
      `${API_URI}/changeStatus`,
      {
        jobId: jobid,
        userId: id,
        status: status,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//get OTP for candidate registration
export const sendOTPCandidate = async (email) => {
  try {
    const response = await axios.post(
      `${API_URI}/sendCandidateOtp/`,
      {
        email: email,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//Candidate SignUp
export const signUpCandidate = async (email, password, otp) => {
  try {
    const response = await axios.post(
      `${API_URI}/registerCandidate/`,
      {
        email: email,
        password: password,
        otp: otp,
      },
      {
        headers: {
          headers,
        },
      }
    );
    // ////console.log(response.data);
    return response.data;
  } catch (error) {
    // ////console.log(error);
  }
};

//to upload user profile photo in s3 bucket
export const getSignedUserProfilePhotoUrl = async (data) => {
  try {
    const response = await axios.post(`${API_URI}/getProfilePhotoUploadLink`, {
      token: data,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//To upload the company logo to AWS S3 Bucket
export const getSignedUrlLogo = async (data) => {
  try {
    const response = await axios.post(`${API_URI}/getLogoUploadLink`, {
      token: data,
    });
    //console.log("WE HAVE THE URL TO UPLOAD THE LOGO API.JS");
    return response.data;
  } catch (error) {
    ////console.log("Error while calling the API ", error.message);
    return error.response.data;
  }
};

//to get the employer stats
export const getEmployerStats = async (token) => {
  try {
    const user = await getEmployerID(token);
    const response = await axios.post(
      `${API_URI}/getEmployerStats`,
      {
        employerId: user,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to get the candidate stats
export const getCandidateStats = async (token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/getCandidateStats`,
      {
        candidateId: user,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//To get the details of the Wishlisted jobs for candidate
export const getWishlistedJobs = async (token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/getShortlistedJobs`,
      {
        id: user,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//get OTP for employer registration
export const sendOTPEmployer = async (email) => {
  try {
    const response = await axios.post(
      `${API_URI}/sendOtp/`,
      {
        email: email,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//Employer SignUp
export const signUpEmployer = async (email, password, otp) => {
  try {
    const response = await axios.post(
      `${API_URI}/registerEmployer/`,
      {
        email: email,
        password: password,
        otp: otp,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//Candidate change password
export const changePasswordCandidate = async (
  currentPassword,
  newPassword,
  token
) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/changePasswordCandidate`,
      {
        currentPassword: currentPassword,
        newPassword: newPassword,
        userId: user,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//Employer change password
export const changePasswordEmployer = async (
  currentPassword,
  newPassword,
  token
) => {
  try {
    const user = await getEmployerID(token);
    const response = await axios.post(
      `${API_URI}/changePassword`,
      {
        currentPassword: currentPassword,
        newPassword: newPassword,
        user: user,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//Candidate forget password->generating reset password token
export const resetPasswordCandidate = async (email) => {
  try {
    const response = await axios.post(
      `${API_URI}/reset-password-token`,
      {
        email: email,
      },
      {
        headers: {
          headers,
        },
      }
    );
    // ////console.log(response.data);
    return response.data;
  } catch (error) {
    // ////console.log(error);
  }
};

//Candidate forget password->reset password
export const forgetPasswordCandidate = async (
  password,
  confirmPassword,
  token
) => {
  try {
    const response = await axios.post(
      `${API_URI}/reset-password`,
      {
        password: password,
        confirmPassword: confirmPassword,
        token: token,
      },
      {
        headers: {
          headers,
        },
      }
    );
    // ////console.log(response.data);
    return response.data;
  } catch (error) {
    // ////console.log(error);
  }
};

//Employer forget password->generating reset password token
export const resetPasswordEmployer = async (email) => {
  try {
    const response = await axios.post(
      `${API_URI}/reset-password-token-employer`,
      {
        email: email,
      },
      {
        headers: {
          headers,
        },
      }
    );
    // ////console.log(response.data);
    return response.data;
  } catch (error) {
    // ////console.log(error);
  }
};

//Employer forget password->reset password
export const forgetPasswordEmployer = async (
  password,
  confirmPassword,
  token
) => {
  try {
    const response = await axios.post(
      `${API_URI}/reset-password-employer`,
      {
        password: password,
        confirmPassword: confirmPassword,
        token: token,
      },
      {
        headers: {
          headers,
        },
      }
    );
    // ////console.log(response.data);
    return response.data;
  } catch (error) {
    // ////console.log(error);
  }
};

//to update the status for completion of the test
export const completedTest = async (token, url) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/completedTest`,
      {
        user: user,
        video: url,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to update the details of an employer
export const updateEmployer = async (token) => {
  try {
    const user = await getEmployerID(token);
    const response = await axios.put(`${API_URI}/Employer/${user}`, {
      headers: {
        headers,
      },
    });
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to get the completed stages of candidate
export const completedStages = async (token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/candidateprogress`,
      {
        user: user,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//Employer registration completed
export const completeEmployerRegistration = async (token) => {
  try {
    const user = await getEmployerID(token);
    const response = await axios.post(
      `${API_URI}/completeRegistration`,
      {
        user: user,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to update the status for completion of the role related questions before mcq
export const completedRoleRelated = async (token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/completedRoleRelated`,
      {
        user: user,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to store the no of tab switches of candidate
export const storeTabSwitchesCount = async (token, count, url) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/storeTabSwitches`,
      {
        user: user,
        tabSwitchCount: count,
        url: url,
      },
      {
        headers: {
          headers,
        },
      }
    );
    //console.log(response.data);
    return response.data;
  } catch (error) {
    //console.log(error);
  }
};

//to fetch the MCQ questions
export const fetchMCQquestions = async () => {
  try {
    const response = await axios.get(`${API_URI}/fetchMcqQuestions/`, {
      headers: {
        headers,
      },
    });
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to fetch the MCQ questions
export const fetchCreativequestions = async (token) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/fetchCreativeQuestions/`,
      {
        user: user,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to fetch the Role questions
export const fetchRolequestions = async (token, role, exp) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/fetchRoleQuestions/`,
      {
        role: role,
        user: user,
        diff: exp,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to store the feedback
export const feedbackSubmit = async (user, rating, feedback) => {
  try {
    const response = await axios.post(
      `${API_URI}/feedback/`,
      {
        user: user,
        feedback: feedback,
        rating: rating,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

//getting the URL for uploading to AWS
export const getSignedUrlExcel = async (tok) => {
  try {
    // console.log("called");
    const response = await axios.post(
      `${API_URI}/uploadExcelLink`,
      { token: tok },
      {
        headers: { headers },
      }
    );
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while calling the API ", error.message);
    return error.response;
  }
};

export const getExtractEmailfromExcel = async (tok) => {
  try {
    const user = await getEmployerID(tok);

    const response = await axios.post(
      `${API_URI}/uploadExcelFromS3`,
      { user: user },
      {
        headers: { headers },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Error while calling the API ", error.message);
    return error.response;
  }
};

export const creatingUserFromEmail = async (emails, form, token) => {
  try {
    // console.log(form);
    const user = await getEmployerID(token);
    const response = await axios.post(
      `${API_URI}/createUsers`,
      { emails: emails, form: form, user: user },
      {
        headers: { headers },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling the API ", error.message);
    return error.response;
  }
};
//to upload resume link
export const resumeLink = async (token, url) => {
  try {
    const user = await getUserID(token);
    // console.log("url", url);
    const response = await axios.post(
      `${API_URI}/resume`,
      {
        user: user,
        res: url,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to upload resume link
export const roleLink = async (token, url1, url2) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/rolelinkstore`,
      {
        user: user,
        role1: url1,
        role2: url2,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};

//to upload resume link
export const creativeLink = async (token, url1, url2) => {
  try {
    const user = await getUserID(token);
    const response = await axios.post(
      `${API_URI}/creativelinkstore`,
      {
        user: user,
        creative1: url1,
        creative2: url2,
      },
      {
        headers: {
          headers,
        },
      }
    );
    ////console.log(response.data);
    return response.data;
  } catch (error) {
    ////console.log(error);
  }
};
