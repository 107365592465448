import React, { useEffect, useRef, useState } from "react";
import { Footer } from "../components/Footer";
import TopNavigation from "../components/NavBar/TopNavigation";
import { uploadFile } from "../service/mlAPI";
import axios from "axios";
import { toast } from "react-toastify";
import { getEmployerID } from "../service/api";
import { getSignedUrlLogo } from "../service/api";
import { useNavigate } from "react-router-dom";

export default function ResumeData() {
  const API_URI = "https://hiremeclub-backend.azurewebsites.net/api/v1";
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [file, setFile] = useState("");
  const [form, setForm] = useState({
    logoUrl: "",
    name: "",
    designation: "",
    phoneNumber: "",
    brandName: "",
    sector: "",
    companyName: "",
    companySize: "Less than 10",
    address: "",
  });
  useEffect(() => {
    const getData = async () => {
      let token = getCookieAndRole();
      const response = await getSignedUrlLogo(token.token);
      // setUrl(response.url);
      setUrl(response.url.split("?")[0]);
      ////console.log(response.url);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        await uploadFile(url, file);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };
    file && getData();
  }, [file]);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "image/png" || selectedFile.type === "image/jpeg")
    ) {
      setFile(selectedFile);
      setForm({ ...form, logoUrl: url });
    } else {
      alert("Please select a Image file.");
      e.target.value = null; // Clear the file input
    }
  };

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendEmployerDetails();
      if (response.success) {
        toast.success("Registered Successfully!");
        navigate("/emp-dashboard");
      } else {
        toast.error("Something went wrong! Please try again later.");
      }
    } catch (error) {
      //console.log("Error is ", error);
      toast.error(
        "Something Went Wrong! Please contact on Telegram @hiremeclub"
      );
    }
  };

  //to send the employer details to the backend
  const sendEmployerDetails = async () => {
    // //console.log(form);
    try {
      let token = getCookieAndRole();
      let user = await getEmployerID(token.token);
      ////console.log(form);
      const response = await axios.post(
        `${API_URI}/Employer/new`,
        {
          ...form,
          user: user,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      // //console.log(error.response.data);
    }
  };

  return (
    <div>
      <div className="bg-gray-50">
        <TopNavigation
          isSticky={false}
          showAvatar={true}
          showNotification={false}
          handleLogout={() => navigate("/emp-login")}
        />
        <div className="employer-data">
          <div className="bg-gray-50 min-h-screen py-8 lg:px-36">
            <div className="container mx-auto sm:p-8">
              <h2 className="text-3xl font-semibold mb-4 text-center">
                Employer's Detail
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="border mb-3 bg-white rounded-lg lg:p-5 sm:p-8">
                  <p className="italic text-red-600 text-xs">* Required</p>
                  <div className="mb-6 flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium "
                      >
                        Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={form.name}
                        onChange={(e) =>
                          setForm({ ...form, name: e.target.value })
                        }
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="designation"
                        className="block mb-2 text-sm font-medium "
                      >
                        Designation <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        value={form.designation}
                        onChange={(e) =>
                          setForm({ ...form, designation: e.target.value })
                        }
                        name="designation"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>
                  </div>

                  {/*Contact Information */}
                  <div className="mb-6  flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="phone"
                        className="block mb-2 text-sm font-medium"
                      >
                        Mobile Number <span className="text-red-600">*</span>
                      </label>
                      <input
                        value={form.phoneNumber}
                        onChange={(e) => {
                          const { value } = e.target;
                          setForm({ ...form, phoneNumber: value });
                          if (!/^\d{10}$/.test(value)) {
                            setError("Please enter a 10-digit number");
                          } else {
                            setError("");
                          }
                        }}
                        type="tel"
                        name="phoneNumber"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                      {error && <p className="text-red-600">{error}</p>}
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="brandName"
                        className="block mb-2 text-sm font-medium "
                      >
                        Brand Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        value={form.brandName}
                        onChange={(e) =>
                          setForm({ ...form, brandName: e.target.value })
                        }
                        type="text"
                        name="brandName"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>
                  </div>
                  <div className="mb-6  flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="sector"
                        className="block mb-2 text-sm font-medium"
                      >
                        Sector <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        value={form.sector}
                        onChange={(e) =>
                          setForm({ ...form, sector: e.target.value })
                        }
                        name="sector"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="companyName"
                        className="block mb-2 text-sm font-medium "
                      >
                        Company Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Ex:- Hirepreneurs Technologies Pvt. Ltd."
                        value={form.companyName}
                        onChange={(e) =>
                          setForm({ ...form, companyName: e.target.value })
                        }
                        name="companyName"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>
                  </div>
                  <div className="mb-6  flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="file"
                        className="block mb-2 text-sm font-medium"
                      >
                        Upload Logo <span className="text-red-600">*</span>
                      </label>
                      <input
                        onChange={handleImageChange}
                        type="file"
                        name="file"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="companyName"
                        className="block mb-2 text-sm font-medium "
                      >
                        Company Size <span className="text-red-600">*</span>
                      </label>
                      <select
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                        value={form.companySize}
                        onChange={(e) =>
                          setForm({ ...form, companySize: e.target.value })
                        }
                      >
                        <option value="0-10">Less than 10</option>
                        <option value="10-50">10-50</option>
                        <option value="50-200">50-200</option>
                        <option value="200-500">200-500</option>
                        <option value="500-1000">500-1000</option>
                        <option value="Greater than 1000">
                          Greater than 1000
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-6  flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="address"
                        className="block mb-2 text-sm font-medium"
                      >
                        Address <span className="text-red-600">*</span>
                      </label>
                      <textarea
                        type="text"
                        value={form.address}
                        onChange={(e) =>
                          setForm({ ...form, address: e.target.value })
                        }
                        rows={5}
                        cols={20}
                        name="address"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-6 flex justify-center">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer showBookingSlot={true} />
      </div>
    </div>
  );
}
