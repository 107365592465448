import React, { useState } from "react";
import Accordion from "./Accordion";

const Filter = () => {
  const [selectedCompanyTypes, setSelectedCompanyTypes] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSalaries, setSelectedSalaries] = useState([]);
  const [selectedExperiences, setSelectedExperiences] = useState([]);
  const [selectedDatePosted, setSelectedDatePosted] = useState([]);
  const [checked, setChecked] = useState([]);
  const [sidebar, setSidebar] = useState(true);
  let all = [];
  const handleCompanyTypeCheckboxChange = (event) => {
    const companyType = event.target.value;
    // //console.log(event.target.value);
    if (event.target.checked) {
      setSelectedCompanyTypes([...selectedCompanyTypes, companyType]);
    } else {
      setSelectedCompanyTypes(
        selectedCompanyTypes.filter((name) => name !== companyType)
      );
    }
  };

  const handleLocationCheckboxChange = (event) => {
    const location = event.target.value;
    // //console.log(event.target.value);
    all = [...checked];
    if (event.target.checked) {
      all.push(event.target.value);
      setSelectedLocations([...selectedLocations, location]);
    } else {
      all = all.filter((c) => c !== event.target.value);
      setSelectedLocations(selectedLocations.filter((loc) => loc !== location));
    }
    setChecked(all);
    // //console.log(checked);
  };

  const handleCategoryCheckboxChange = (event) => {
    const category = event.target.value;
    if (event.target.checked) {
      setSelectedCategories([...selectedCategories, category]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((cat) => cat !== category)
      );
    }
  };

  const handleSalaryCheckboxChange = (event) => {
    const salary = event.target.value;
    if (event.target.checked) {
      setSelectedSalaries([...selectedSalaries, salary]);
    } else {
      setSelectedSalaries(selectedSalaries.filter((sal) => sal !== salary));
    }
  };

  const handleExperienceCheckboxChange = (event) => {
    const experience = event.target.value;
    if (event.target.checked) {
      setSelectedExperiences([...selectedExperiences, experience]);
    } else {
      setSelectedExperiences(
        selectedExperiences.filter((exp) => exp !== experience)
      );
    }
  };

  const handleDatePostedCheckboxChange = (event) => {
    const datePosted = event.target.value;
    if (event.target.checked) {
      setSelectedDatePosted([...selectedDatePosted, datePosted]);
    } else {
      setSelectedDatePosted(
        selectedDatePosted.filter((date) => date !== datePosted)
      );
    }
  };

  const companyTypes = ["Startup", "Unicorn", "Business", "MAANG"];
  const locations = [
    "Delhi NCR",
    "Bengaluru",
    "Pune",
    "Mumbai",
    "Hyderabad",
    "Kolkata",
    "Chenni",
  ];
  const category = ["BDE", "Sales", "Marketing", "Design"];
  const salary = ["Upto INR 20k/m", "INR 20k/m - 50k/m", "Above INR 50k/m"];
  const experience = ["Fresher", "1+ Years", "3+ Years", "Intern"];
  const datePosted = ["Today", "Last 7 Days", "Last 15 Days", "Last Month"];
  // Add similar handlers for other filter options

  return (
    <div className="bg-white shadow-md p-2 m-4 rounded-xl overflow-y-auto">
      <div className="p-4 flex items-center justify-between border-b border-gray-300">
        <h2 className="font-bold text-lg">Filter Jobs</h2>
        <div>
          <button
            className="px-4 py-2 mr-2 text-blue-600 border-solid border-2 border-blue-600 rounded-xl"
            onClick={() => {
              setSelectedCompanyTypes([]);
              setSelectedLocations([]);
              // Clear other filter states
            }}
          >
            Clear All
          </button>
          <button className="lg:hidden" onClick={() => setSidebar(!sidebar)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Accordions for filter categories */}
      {sidebar && (
        <div className="border-gray-300 pt-4">
          {/* Company Name */}
          <div className="mb-4">
            <Accordion title="Company Types">
              <div>
                {companyTypes.map((type) => (
                  <label className="flex items-center space-x-2" key={type}>
                    <input
                      type="checkbox"
                      value={type}
                      checked={selectedCompanyTypes.includes(type)}
                      onChange={handleCompanyTypeCheckboxChange}
                    />
                    <span>{type}</span>
                  </label>
                ))}
              </div>
            </Accordion>
          </div>
          {/* Location */}
          <div className="mb-4">
            <Accordion title="Locations">
              <div>
                {locations.map((type) => (
                  <label className="flex items-center space-x-2" key={type}>
                    <input
                      type="checkbox"
                      value={type}
                      checked={selectedLocations.includes(type)}
                      onChange={handleLocationCheckboxChange}
                    />
                    <span>{type}</span>
                  </label>
                ))}
              </div>
            </Accordion>
          </div>
          {/* Category */}
          <div className="mb-4">
            <Accordion title="Category">
              <div>
                {category.map((type) => (
                  <label className="flex items-center space-x-2" key={type}>
                    <input
                      type="checkbox"
                      value={type}
                      checked={selectedCategories.includes(type)}
                      onChange={handleCategoryCheckboxChange}
                    />
                    <span>{type}</span>
                  </label>
                ))}
              </div>
            </Accordion>
          </div>
          {/*Salary Box */}
          <div className="mb-4">
            <Accordion title="Salary">
              <div>
                {salary.map((type) => (
                  <label className="flex items-center space-x-2" key={type}>
                    <input
                      type="checkbox"
                      value={type}
                      checked={selectedSalaries.includes(type)}
                      onChange={handleSalaryCheckboxChange}
                    />
                    <span>{type}</span>
                  </label>
                ))}
              </div>
            </Accordion>
          </div>
          {/*Experience Box */}
          <div className="mb-4">
            <Accordion title="Experience">
              <div>
                {experience.map((type) => (
                  <label className="flex items-center space-x-2" key={type}>
                    <input
                      type="checkbox"
                      value={type}
                      checked={selectedExperiences.includes(type)}
                      onChange={handleExperienceCheckboxChange}
                    />
                    <span>{type}</span>
                  </label>
                ))}
              </div>
            </Accordion>
          </div>
          {/*Date Posted */}
          <div className="mb-4">
            <Accordion title="Date Posted">
              <div>
                {datePosted.map((type) => (
                  <label className="flex items-center space-x-2" key={type}>
                    <input
                      type="checkbox"
                      value={type}
                      checked={selectedDatePosted.includes(type)}
                      onChange={handleDatePostedCheckboxChange}
                    />
                    <span>{type}</span>
                  </label>
                ))}
              </div>
            </Accordion>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
